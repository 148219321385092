<template>
    <!-- BEGIN login -->
    <div class="login login-with-news-feed">
        <!-- BEGIN news-feed -->
        <div class="news-feed">
            <div class="news-image"></div>
            <div class="content-bg"></div>

            <div class="home-content">
                <h1>Welcome to TECHSTORE SMARTOP</h1>
                <h3>Loan Management Solution</h3>
                <p>
                    Click the icon below to download the application from
                    Apple's App store, Google's Play Store.<br />
                    After downloading, simply follow the screen instructions to
                    start mobile app!
                </p>
                <div class="tw-space-x-4">
                    <a v-show="app_store_url" :href="app_store_url" class="btn btn-theme btn-outline-white"
                        ><Icon size="50" type="logo-apple" />
                        <span class="d-inline-block text-left">
                            <small class="font-weight-light d-block"
                                >Available on the</small
                            >
                            App Store
                        </span></a
                    >
                    <a v-show="apk_file_url" :href="apk_file_url" class="btn btn-theme btn-outline-white"
                        ><Icon size="50" type="logo-android" />
                        <span class="d-inline-block text-left">
                            <small class="font-weight-light d-block"
                                >Download on the</small
                            >
                            Google Play
                        </span></a
                    >
                </div>
            </div>
            <div class="news-caption">
                <h4 class="caption-title">
                    <b>{{ $root.appName }}</b> SMARTOP
                </h4>
                <p>
                    Modernize and Streamline Profitable Lending Growth Without
                    Disruption.
                </p>
            </div>
        </div>
        <!-- END news-feed -->

        <!-- BEGIN login-container -->
        <div class="login-container">
            <!-- BEGIN login-header -->
            <div class="login-header mb-30px">
                <div class="brand">
                    <div class="d-flex align-items-center">
                        <span class="logo"></span>
                        <b>{{ $root.appName }}</b> &nbsp;SMARTOP
                    </div>
                    <small>Loan Management Solution</small>
                </div>
                <div class="icon">
                    <i class="fa fa-sign-in-alt"></i>
                </div>
            </div>
            <!-- END login-header -->

            <!-- BEGIN login-content -->
            <div class="login-content">
                <div
                    class="alert alert-danger fade show ts-mb-4"
                    v-show="errorMessage != ''"
                >
                    {{ errorMessage }}
                </div>
                <form class="fs-13px" @submit.prevent="login">
                    <div class="form-floating mb-15px">
                        <input
                            v-model.trim="model.username"
                            type="text"
                            class="form-control h-45px fs-13px"
                            placeholder="Email Address"
                            id="username"
                            :class="{ 'is-invalid': errors.has('username') }"
                        />
                        <label
                            for="username"
                            class="d-flex align-items-center fs-13px text-gray-600"
                            >User Name</label
                        >
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('username')"
                        >
                            {{ errors.first("username") }}
                        </div>
                    </div>
                    <div class="form-floating mb-15px">
                        <input
                            v-model="model.password"
                            type="password"
                            class="form-control h-45px fs-13px"
                            placeholder="Password"
                            id="password"
                            :class="{ 'is-invalid': errors.has('password') }"
                        />
                        <label
                            for="password"
                            class="d-flex align-items-center fs-13px text-gray-600"
                            >Password</label
                        >
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('password')"
                        >
                            {{ errors.first("password") }}
                        </div>
                    </div>
                    <div class="form-check mb-30px">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                            id="rememberMe"
                        />
                        <label class="form-check-label" for="rememberMe">
                            Remember Me
                        </label>
                    </div>
                    <div class="mb-15px">
                        <!-- <button
                            class="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                            @click.prevent="login"
                        >
                            Sign me in
                        </button> -->

                        <ts-button
                            type="submit"
                            class="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                            :waiting="loggingIn"
                        >
                            Sign me in</ts-button
                        >
                    </div>
                    <div class="mb-40px pb-40px text-inverse">
                        Do you forgot password ? Click
                        <router-link to="#" class="text-success"
                            >here</router-link
                        >
                        to recovery.
                    </div>
                    <div class="app-button text-center">
                        <p class="text-center tw-text-gray-600">
                            Click the icon below to download the application
                            from Apple's App store, Google's Play Store.<br />
                            After downloading, simply follow the screen
                            instructions to start mobile app!
                        </p>
                        <div class="tw-space-x-2">
                            <a v-show="app_store_url" :href="app_store_url" class="btn btn-sm btn-dark">
                                <span class="d-inline-block text-left">
                                    <small class="font-weight-light d-block"
                                        >Available on the</small
                                    >
                                    <Icon size="25" type="logo-apple" />App
                                    Store
                                </span></a
                            >
                            <a v-show="apk_file_url" :href="apk_file_url" class="btn btn-sm btn-dark">
                                <span class="d-inline-block text-left">
                                    <small class="font-weight-light d-block"
                                        >Download on the</small
                                    >
                                    <Icon size="25" type="logo-android" />
                                    Google Play
                                </span></a
                            >
                        </div>
                    </div>
                    <hr class="bg-gray-600 opacity-2" />
                    <div
                        class="text-gray-600 text-center text-gray-500-darker mb-0"
                    >
                        &copy; TECHSTORE All Right Reserved 2021
                    </div>
                </form>
            </div>
            <!-- END login-content -->
        </div>
        <!-- END login-container -->
    </div>

    <!-- END login -->
</template>

<script>
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
import AppOptions from "../config/AppOptions.vue";
import api from "@/api/login-api";

import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
    name: "login",
    data() {
        return {
            errors: new Errors(),
            errorMessage: "",
            loggingIn: false,
            app_store_url: null,
            apk_file_url: null,
            model: {
                guard: "admin",
                username: null,
                password: null
            }
        };
    },
    created() {
        AppOptions.appEmpty = true;
        this.fetchResource()
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appEmpty = false;
        next();
    },
    methods: {
        ...mapActions("auth/user", ["getCurrentUser", "getAppUrl"]),
        login() {
            this.loggingIn = true;
            this.errors = new Errors();
            this.errorMessage = "";
            api.login(this.model)
                .then(async response => {
                    this.$cookies
                        .set("token", response.data.access_token, "Session")
                        .set(
                            "refresh_token",
                            response.data.refresh_token,
                            "Session"
                        );
                    this.$store.commit("setIsLock", false);

                    await this.fetchUser();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.errorMessage = error.message;
                    this.loggingIn = false;
                });
        },
        async fetchUser() {
            await this.getCurrentUser()
                .then(async response => {
                    localStorage.setItem(
                        "authUser",
                        JSON.stringify(response.data)
                    );
                    this.$store.commit("setAuthUser", response.data);

                    this.$nextTick(() => {
                        AppOptions.pageEmpty = false;
                        this.$forceUpdate();
                    });
                    await this.$router.go(
                        this.$route.query.redirectTo || { name: "home" }
                    );
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    this.loggingIn = false;
                });
        },
        async fetchResource() {
            this.getAppUrl()
                .then(response => {
                    this.apk_file_url = response.apk_file_url
                    this.app_store_url = response.app_store_url
                })
        }
    }
};
</script>
<style scoped lang="scss">
.news-image {
    background-image: url("../assets/img/login-bg/login-bg-11.jpg");
}
.news-feed .home-content {
    z-index: 1020;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    color: rgba(255, 255, 255, 0.8);
    margin-top: -120px;
    padding: 0 15px;
    text-align: center;

    h1 {
        font-size: 60px;
        font-weight: 600;
    }
    h3 {
        font-size: 32px;
        color: #fff;
        margin: 0 0 30px;
        font-weight: 300;
    }
    p {
        margin-bottom: 60px;
        font-size: 0.8125rem;
    }
}
.news-feed .content-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.news-feed .content-bg:before {
    content: "";
    background: url("../assets/img/login-bg/bg-content-cover.png");
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
@media screen and (max-width: 767px) {
  .app-button {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1230px) {
    .app-button {
        display: none;
    }
    .news-feed .home-content {
        z-index: 1020;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        color: rgba(255, 255, 255, 0.8);
        margin-top: -120px;
        padding: 0 15px;
        text-align: center;

        h1 {
            font-size: 34px;
            font-weight: 600;
        }
        h3 {
            font-size: 20px;
            color: #fff;
            margin: 0 0 15px;
            font-weight: 300;
        }
        p {
            margin-bottom: 30px;
            font-size: 0.8125rem;
        }
    }
    .btn.btn-theme {
        padding: 5px 10px;
        font-weight: 600;
        border-radius: 3px;
    }
}
@media all and (min-width: 1231px) {
  .app-button {
    display: none;
  }
}
</style>
